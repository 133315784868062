<template>
	<div class="iframe_main">
        <div class="prompt" v-if="pageSrc == ''">
            <a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original" :image-style="{height:'100px'}">
                <template #description>
                    <span>账号已失效，重新登录~</span>
                </template>
                <a-button type="primary" @click="loginOut">重新登录</a-button>
            </a-empty>
        </div>
        <div class="prompt" v-if="pageSrc == 'nullPage'">
            <a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original" :image-style="{height:'100px'}">
                <template #description>
                    <span>页面加载中...</span>
                </template>
                <a-button type="primary" @click="refreshPage">刷新页面</a-button>
            </a-empty>
        </div>
        <iframe id='iframe' class="iframe" v-if="pageSrc && pageSrc != 'nullPage'" ref="iframe" :src="pageSrc"></iframe>
    </div>
</template>

<script>
import method from '../../methods/method'
import config from '../../methods/config'

export default {
	data() {
        return {
            pageSrc:'nullPage',
        }
    },
    created() {
        
    },
    mounted(){
        this.getIframeUrl()
        //监听子页面数据
        let that = this
        window.addEventListener('message', function(e){
            that.getJumpDataUrl(e)
        })
    },
    methods: {
        //iframe页面地址
        getIframeUrl(){
            let url = config.stagingUrl
            if(url){
                let newUrl = this.getWebsite(url)
                this.pageSrc = newUrl +'&source=dongxiao'
            }
        },
        //iframe页面内跳转地址
        getJumpDataUrl(e){
            if(e.data.data){
                if(e.data.data.jumpUrl){
                    let newUrl = this.getWebsite(e.data.data.jumpUrl)
                    window.open(newUrl,'_blank')
                }else if(e.data.data.pageType == 'login'){
                    this.pageSrc = ''
                }
            }
        },
        //生成网址
        getWebsite(newUrl){
            if(newUrl){
                if(newUrl.indexOf('tiken=') < 0){
                    let token = method.get_local('token');
                    newUrl += newUrl.indexOf('?') > -1 ? '&' : '?'
                    newUrl += 'rttoken=' + token
                }
                return newUrl
            }
            return ''
        },
        loginOut(){
            this.$router.replace('/login')
        },
        refreshPage(){
            location.reload();
        },
    }
}
</script>

<style lang="less" scoped>
    .iframe_main{
        width:100%;
        height:100%;
        overflow: hidden;
        position: relative;
    }
    .iframe{
        width:100%;
        height:100%;
        overflow: hidden;
        border:0;
        z-index:10;
        position:absolute;
        top:0;
        left:0;
    }
    .prompt{
        width:200px;
        height:200px;
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        z-index:5;
    }
</style>